import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../services/adminServices/admin-service.service';
import {AlertService} from '../..';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../models/user';
import {Doctor} from '../../models/doctor';
import {Address} from '../../models/address';
import { v4 as uuidv4 } from 'uuid';
import {LoginService} from '../../services/login.service';
import {AdminChatService} from '../../services/chat/admin-chat.service';
import {FileApiService} from '../../providers/file.api.service';

//#region of token
declare function generateToken(key):any;
//#end region

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  signUpForm:FormGroup;
  userModel:User = new User();
  profile:Doctor = new Doctor();



  constructor( private route: ActivatedRoute,
               private router: Router,
               private adminServiceService:AdminService,
               private loginService:LoginService,
               protected alertService: AlertService,
               private apiChatService:AdminChatService,
               private fileApi:FileApiService,
               public api:AdminChatService,
               fb: FormBuilder) {

    this.signUpForm = fb.group({
      'userName': [null, Validators.required],
       'password': ['', [Validators.required, this.passwordValidator, Validators.min(6)]],
       'email': [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
    });
  }


  passwordValidator(control: any) {
    const password = control.value;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);

    if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecialChar) {
      return { passwordStrength: true };
    }

    return null;
  }


  keyDownFunction(event) {
    if(event.keyCode == 13) {
      this.create();
    }
  }

  ngOnInit(): void {
    this.profile.Address = new Address();
  }

  create(){
    this.markFormTouched(this.signUpForm);

    if(this.signUpForm.valid) {
      let formValues = this.signUpForm.getRawValue();

      /*Verify if email has already exists*/
      this.adminServiceService.isEmailExists(formValues.email).subscribe(exists => {
       if(exists === false){

      this.userModel = new  User();
      this.userModel.userName = formValues.userName;
      this.userModel.password = formValues.password;
      this.userModel.authToken = generateToken(formValues.password);
      this.userModel.uid = uuidv4();

      /*creation virtual directory in the server*/
      this.fileApi.createDirectory(this.userModel.userName).subscribe(()=>{});

      /*create user profile for chat through fire base*/
      this.loginService.fireSingUp(formValues.email, formValues.password).then(res => {

        console.log(res);
        /*define user creation for firebase*/
        this.apiChatService.createUser(this.userModel.uid,
          {
            name: formValues.userName,
            email: formValues.email,
            uid: formValues.password,
            conversations: []
          }
        ).then(r => {
          console.log(r);
        });
        //console.log(res);
      });

      this.adminServiceService.createUer(this.userModel).subscribe(res => {
        if(res.idUser > 0){
          this.profile.UserId = res.idUser;
          this.profile.FirstName = this.userModel.userName;
          this.profile.LastName = "";
          this.profile.Email = formValues.email;
          this.profile.PhoneNumber = "";
          this.profile.WebPage = "";
          this.profile.photoUrl = null;
          this.userModel.RolId = res.RolId;

          localStorage.setItem('token',this.userModel.authToken);
          localStorage.setItem('userInfo',JSON.stringify(res));
          const code = this.loginService.generateCode()
          localStorage.setItem('code',code);

          this.startChat(this.userModel);

          this.adminServiceService.sendEmailConfirmation(formValues.email,code).subscribe(res =>{
            if(res){
              this.alertService.success("Your verification code was already send at your inbox",{id:'alert-1',autoClose:this.options.autoClose});
            }
          });
          this.adminServiceService.createProfile(this.profile).subscribe( res =>{
            if(res.idProfile > 0){
              this.router.navigate(['/confirmation/',formValues.email]).then(() => {});
              //this.router.navigate(['/index/profile']);
            }
          });
        }
      });
       }else{
         this.alertService.warn("The email already exists, please contact Administrator",{id:'alert-1',autoClose:this.options.autoClose});
       }
      });/*end verification*/
    }else{
     this.alertService.warn("Please fill the input texts",{id:'alert-1',autoClose:this.options.autoClose});
    }

  }

  cancel(){
    this.router.navigate(['/login']).then(() => {});
  }

  startChat(currentUser){
    this.api.addNewChat().then(async () =>{
      let user = this.api.getAdminUser();
      let promise = await this.api.addInitialConvo(user,currentUser);
    });

  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) { control.markAsTouched(); this.markFormTouched(control); }
      else { control.markAsTouched(); }
    });
  };


  migrateInfo(){

    this.adminServiceService.getUsersToMigrate().subscribe(users => {

    users.forEach(user => {

      //get email associated
      this.adminServiceService.getByUserId(user.idUser).subscribe(profile => {

      if(profile !== null) {
        this.loginService.fireSingUp(profile.Email, user.password).then(res => {

          this.userModel = new User();
          this.userModel.userName = user.userName;
          this.userModel.password = user.password;
          this.userModel.authToken = generateToken(user.password);
          this.userModel.uid = uuidv4();
          /*define user creation for firebase*/
          this.apiChatService.createUser(this.userModel.uid,
            {
              name: user.userName,
              email: profile.Email,
              uid: user.password,
              conversations: []
            }
          ).then(r => {

            this.adminServiceService.updateById(user.idUser, this.userModel).subscribe(r => {
              console.log("MIGRATED SUCCESSFULLY!!!");
              alert("MIGRATED SUCCESSFULLY!!!");
            });
          });

        });
      }  });
    })// end of user map
    });

  }

}
